body {
  font-family: sans-serif;
}


form {
  margin: 3rem auto 0;
  width: 24rem;
}

  label {
    display: block;
    color: #fff;
  }

  input,
  select,
  textarea {
    margin: 0.5rem 0;
    padding: 0.5rem;
    width: inherit;
    height: 2rem;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
  }

  input[type="submit"] {
    height: 2.5rem;
    font-size: 1rem;
    color: #fff;
    background: #0971f1;
    cursor: pointer;
  }

  textarea {
    height: 5rem;
  }




.css-1fz075e-MuiPaper-root-MuiAlert-root .MuiAlert-icon{
  width: 40px;
}



.formDiv{
  padding-top: 50px;
  padding-bottom: 50px;
}