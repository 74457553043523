.ContactBody{

}


.TextAbout{
    margin: auto;
    position: relative;
}

.TitleContact{
    font-size: 3rem;
    max-width: 60.4rem;
    font-weight: 700;
    line-height: 1.3;

}
@media screen and (max-width: 1200px) {
    .ContactBody {
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
    }
  
    .ContactBody > div {
      margin: 0;
    }
  }