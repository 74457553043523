

.customWidth {
width: 300px; /* Remplacez 200px par la largeur souhaitée */
/* Autres styles personnalisés si nécessaire */
}
/* Pour les écrans de 800px et moins */
@media screen and (max-width: 100px) {
    .responsive-grid {
      grid-template-columns: 1fr;
      display: grid;
      justify-items: center;
    }
    .responsiveFilter{
        margin-bottom: 30px;
        width: 96%;
    }
  }
  
  /* Pour les écrans de plus de 800px */
  @media screen and (min-width: 1001px) {
    .responsive-grid {
      grid-template-columns: 1fr 2fr; /* Par exemple, deux colonnes pour les écrans plus larges */
      display: grid;
      display: grid;
      grid-template-columns: 0.8fr 3.2fr;
      gap: '20px';
      margin-left: '20px';
    }
    .responsiveFilter{
       
    }
  }