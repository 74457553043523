

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Samarkan';
  src: url('./font/SAMARN__.TTF') format('truetype');
  /* Autres propriétés de style si nécessaire */
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./font/Roboto-Regular.ttf') format('truetype');
  /* Autres propriétés de style si nécessaire */
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./font/Roboto-Bold.ttf') format('truetype');
  /* Autres propriétés de style si nécessaire */
}

.background-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./logo-noir-or-vecto-uni_1.png'); /* Remplacez par le chemin correct */
  background-size: cover;
  filter: blur(10px); /* Ajustez la valeur de flou selon vos préférences */
  z-index: -1; /* Placez la couche en arrière-plan */
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}


.BgMain{
  background-image: url('./logo-noir-or-vecto-uni_1.png');
}

main{
  margin-top: 200px;
}