.footerclass{
    max-width: 100%;
    margin-left: 100px;
    margin-right: 100px
}


@media (max-width: 900px){
    .footerclass{
       
        margin-left: 0px;
        margin-right: 0px;
    }
}