
.TitleAbout{
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.3;

}

.DescAbout{
    margin-top: 35px;
    font-weight: 400;
    line-height: 1.56;
    font-size: 1.2rem;
    text-align: justify;
    max-width: 800px;

}

.About{
    display: grid;
    padding: 5ch;
    margin-top: 5ch;
}

.AboutGallery{
    display: grid;
    padding: 5ch;
}



@media (min-width: 500px){
    .AboutGallery{
        
        padding: 1ch;
        
    }
    
    
    
    }


@media (min-width: 900px){
.About {
    gap: 70px;
    grid-template-columns: 2fr 1fr;
   
}


}

@media (min-width: 1000px){
.AboutGallery {
    gap: 70px;
    grid-template-columns: 1.3fr 1.7fr;


}
}
@media (max-width: 900px){

.ImgAbout{
    margin-top: 5px;
}

}

.ImgAbout{
    width: 300px;
    border-radius: 20px;
}

.ImgAboutDiv{
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.TextAbout{
    margin: auto;
    position: relative;
}


.ImgBackH{
    width: 100%;
    max-width: 100vw; /* Définit une largeur maximale équivalente à la largeur de la vue */
    height: auto;
    margin-top: 120px;
}

/* @media (min-width: 900px){
.BackgroundImg {
    min-height: calc(100vh - 9rem);
}
} */



.SwipperHome{
    padding-top: 40px;

}


.Form{
    display: flex;
    width: 40%;
}