.LogoStyle {
    margin-left: 180px;
    width: 100px;
}

.BoxHeader {
    margin-right: 180px;
}



.header-bg-two {
    background-color: transparent;
    height: 50px;
    background: transparent;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    width: 100%;
    height: 100px;
}

.header {
    height: 50px;
    background: transparent;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
}

/* styles.css */

