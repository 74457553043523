.LogoStyle {
    margin-left: 180px;
    width: 100px;
}

.BoxHeader {
    margin-right: 180px;
    padding-bottom: 20px; /* Ajoutez cette ligne */
}



.header-bg {
    background-color: transparent;
    height: auto;
    background: transparent;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    width: 100%;
    height: 700px;
    background-image: url('../HeaderBack.jpg'); /* Remplacez par le chemin correct */
    background-attachment: fixed;
    background-size: cover;
    background-position: center; /* Centre l'image horizontalement */
}

.header {
    height: auto;
    background: transparent;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
}

/* styles.css */

